interface ISpecification {
  name: string
  value: string
}
export default class PurchasingRequestNormalizer {
  static normalize(
    memberResponse: MemberSerializerResponse<IPurchasingRequest>
  ): IPurchasingRequest {
    const data = memberResponse.data.attributes
    const products = memberResponse.included
      .filter((requestItem) => requestItem.type === 'product_request_item')
      .map((product) => product.attributes)
    const mainItems = products.filter((requestItem) => !requestItem.parent_id)
    const deliveryItems = memberResponse.included
      .filter((requestItem) => requestItem.type === 'delivery_request_item')
      .map((product) => product.attributes)
    const mapDeliveryType = (type: string) => (type === 'return' ? 'return_delivery' : type)

    const rental = memberResponse.included.find((requestItem) => requestItem.type === 'rental')
      ?.attributes as IRental

    return {
      ...data,
      rental,
      open_with_overview: true,
      rental_request_deliveries: deliveryItems.map((deliveryItem) => ({
        ...deliveryItem,
        unit_price: deliveryItem.net_price,
        name: deliveryItem.item.display_name,
        delivery_date: deliveryItem.item.delivery_date,
        line_item_type: mapDeliveryType(deliveryItem.item.delivery_type),
        cargos: deliveryItem.cargos
      })),
      rental_request_items: mainItems.map((mainRequestItem) => ({
        ...mainRequestItem,
        name: mainRequestItem.item.name,
        supplier_station_id: data.supplier_station_id,
        unit_price: mainRequestItem.net_price,
        sub_rental_request_items: products
          .filter((productItem) => productItem.parent_id === mainRequestItem.id)
          .map((subRequestItem) => ({
            ...subRequestItem,
            name: subRequestItem.item.name,
            supplier_station_id: data.supplier_station_id,
            line_item: {
              ...subRequestItem.item,
              category_segment_id: subRequestItem.item.catalog_info_id,
              category_segment_name: subRequestItem.item.name,
              free: subRequestItem.included,
              line_item_id: subRequestItem.parent_id,
              line_item_type: subRequestItem.item.item_type,
              attribute_values: subRequestItem.item.specifications
            },
            sub_rental_request_items: []
          })),
        line_item: {
          ...mainRequestItem.item,
          free: false,
          category_segment_id: mainRequestItem.item.catalog_info_id,
          category_segment_name: mainRequestItem.item.name,
          line_item_type: mainRequestItem.item.item_type,
          attribute_values: mainRequestItem.item.specifications.map(
            (spec: ISpecification, index: number) => ({
              id: index,
              question: spec.name,
              display_value: spec.value
            })
          )
        }
      }))
    }
  }
}
